/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .main_logo{
  width:220px;
  height: 70px;
}

.navbar-brand {
  margin: auto !important;
}

.navbar {
  background: transparent !important;
  z-index: 999;
  box-shadow: none;
  padding-right: 1em;
  padding-top: 1em;
  transition: all .3s ease-in;
}
.navbar.active {
  background: #030A11 !important;
  padding-bottom: 0px;
}
@media (min-width: 992px) {
  .navbar {
    padding: unset !important;
  }
}

.Nav a {
  cursor: pointer;
}

.navbar-dark .nav-link a {
  cursor: pointer !important;
}

@media (max-width: 991.7px) {
  .navbarTransition {
    transform: translateY(-100%);
    transition: all .3s ease-in !important;
  }
  .navbar-collapse,
  .navbar.active {
    /* background: rgba(8, 63, 136, 0.9) !important; */
    z-index: 10;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1195px) {
  .main_logo {
    margin-left: unset !important;
  }
}
.main_logo {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 992px) {
  .main_logo {
    margin-left: 2rem;
  }
}
@media (max-width: 991px) {
  .navbar {
    padding-top: unset !important;
  }
}

@media (max-width: 320px) {
  .main_logo {
    width: 170px;
    margin-left: 3rem;
  }
}
@media (min-width: 321px) and (max-width: 374.9px) {
  .main_logo {
    margin-left: 3rem;
  }
}
@media (min-width: 375px) and (max-width: 767.9px) {
  .main_logo {
    margin-left: 3rem;
  }
}
@media (max-width: 767px) {
  .main_logo {
    width: 200px;
    height: auto;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-AccessibilityMenu-show {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .desktop-AccessibilityMenu-show {
    display: none;
  }
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 2;
  }
}