.banner {
    position: relative;
    overflow: hidden;
    padding-bottom: 2rem;
}

#banner-video-desktop,
#banner-video-mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bannercontainer {
    position: relative;
    overflow: hidden;
}

.banner-heading {
    color: #00AAE4;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
}

.banner-subheading {
    color: #FFF;
    font-family: Poppins;
    text-align: center;
    font-weight: 500;
}

.features-list {
    list-style: none;
}

.features {
    position: relative;
    padding-left: 2rem;
    color: #FFF;
    font-family: Poppins;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 1rem;

}

.features::before {
    content: '';
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url('https://d2b7dijo04ypct.cloudfront.net/graduationmortgage/check-square.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.features-btn {
    border-radius: 2rem;
    border: 1.82px solid #00AAE4;
    background: #040B12;
    padding: 0.5rem 2rem;
    color: #FFF;
    font-family: Poppins;
    font-weight: 600;
    font-size: 26px;
}

.features-btn:hover {
    background: #00AAE4;
    color: #040B12;
}

@media screen and (min-width: 992px) {
    .banner-deskvideo {
        display: block;
    }

    .banner-mobvideo {
        display: none;
    }

    .bannercontainer {
        margin-top: 5rem;
    }

    .banner-heading {
        font-size: 5rem;
    }

    .banner-subheading {
        font-size: 1.75rem;
    }

    .graduation-cap {
        margin-left: 10.65rem;
        margin-bottom: -3.25rem;
    }
    .features-container{
        background-image: url('https://d2b7dijo04ypct.cloudfront.net/graduationmortgage/vector-desk.webp');
        background-size: 90% 100%;
        background-repeat: no-repeat;
        background-position: left;
        padding: 5rem 0 7rem 0rem;
    }
    .features-row {
        margin-top: 2rem;
    }

    .features {
        font-size: 1.25rem;
    }

    .features::before {
        margin-top: 5px;
    }

    .features-btn {
        position: absolute;
        transform: translate(4rem, 1rem);
    }
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(1, 4, 7, 0.85) 0%, rgba(1, 4, 7, 0.85) 100%);
        pointer-events: none;
    }
    .features-form-col {
        margin-top: 2rem;
    }
    .mobile-btn{
        display: none;
    }
}

@media (max-width: 991px) {
    .banner-deskvideo {
        display: none;
    }

    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(1, 4, 7, 0.85) 0%, rgba(1, 4, 7, 0.85) 100%);
        pointer-events: none;
        opacity: 0.5;
    }
    .banner {
        padding-bottom: 2rem;
    }

    .banner-mobvideo {
        display: block;
    }
    .features-row{
        flex-direction: column-reverse;
    }
    .desktop-btn{
        display: none;
    }
    .mobile-btn{
        margin-inline: auto;
        display: table;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    .banner-heading{
        font-size: 3rem;
    }
    .banner-subheading{
        font-size: 1.75rem;
    }
    .graduation-cap{
        width: 70px;
        margin-top: 3rem;
        margin-bottom: -3.2rem;
    }
    .features-list{
        padding-left: 0;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 1399.9px) and (min-width: 1200px) {
    .graduation-cap{
        margin-left: 5rem;
    }
    .features{
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 1199.9px) and (min-width: 992px) {
    .features{
        font-size: 1rem;
    }
}
@media screen and (max-width: 329.9px) and (min-width: 320px) {
    .graduation-cap{
        width: 60px;
        margin-left: -2.5%;
    }
}
@media screen and (max-width: 334.9px) and (min-width: 330px) {
    .graduation-cap{
        margin-left: -2vw;
    }
}
@media screen and (max-width: 339.9px) and (min-width: 335px) {
    .graduation-cap{
        margin-left: -1vw;
    }
}
@media screen and (max-width: 344.9px) and (min-width: 340px) {
    .graduation-cap{
        margin-left: -1vw;
    }
}
@media screen and (max-width: 349.9px) and (min-width: 345px) {
    .graduation-cap{
        margin-left: 0vw;
    }
}
@media screen and (max-width: 354.9px) and (min-width: 350px) {
    .graduation-cap{
        margin-left: 1vw;
    }
}
@media screen and (max-width: 359.9px) and (min-width: 355px) {
    .graduation-cap{
        margin-left: 1.75vw;
    }
}
@media screen and (max-width: 364.9px) and (min-width: 360px) {
    .graduation-cap{
        margin-left: 2vw;
    }
}
@media screen and (max-width: 369.9px) and (min-width: 365px) {
    .graduation-cap{
        margin-left: 3vw;
    }
}
@media screen and (max-width: 374.9px) and (min-width: 370px) {
    .graduation-cap{
        margin-left: 3.5vw;
    }
}
@media screen and (max-width: 379.9px) and (min-width: 375px) {
    .graduation-cap{
        margin-left: 4vw;
    }
}
@media screen and (max-width: 384.9px) and (min-width: 380px) {
    .graduation-cap{
        margin-left: 4.5vw;
    }
}
@media screen and (max-width: 389.9px) and (min-width: 385px) {
    .graduation-cap{
        margin-left: 5vw;
    }
}
@media screen and (max-width: 394.9px) and (min-width: 390px) {
    .graduation-cap{
        margin-left: 5.5vw;
    }
}
@media screen and (max-width: 399.9px) and (min-width: 395px) {
    .graduation-cap{
        margin-left: 6vw;
    }
}
@media screen and (max-width: 404.9px) and (min-width: 400px) {
    .graduation-cap{
        margin-left: 6.5vw;
    }
}
@media screen and (max-width: 409.9px) and (min-width: 405px) {
    .graduation-cap{
        margin-left: 7vw;
    }
}
@media screen and (max-width: 414.9px) and (min-width: 410px) {
    .graduation-cap{
        margin-left: 7.5vw;
    }
}
@media screen and (max-width: 419.9px) and (min-width: 415px) {
    .graduation-cap{
        margin-left: 8vw;
    }
}
@media screen and (max-width: 424.9px) and (min-width: 420px) {
    .graduation-cap{
        margin-left: 8.5vw;
    }
}
@media screen and (max-width: 429.9px) and (min-width: 425px) {
    .graduation-cap{
        margin-left: 9vw;
    }
}
@media screen and (max-width: 499.9px) and (min-width: 430px) {
    .graduation-cap{
        margin-left: 9.5vw;
    }
}
@media screen and (max-width: 767.9px) and (min-width: 576px) {
    .graduation-cap{
        margin-left: 17vw;
    }
}
@media screen and (max-width: 991.9px) and (min-width: 768px) {
    .graduation-cap{
        width: 115px;
        margin-left: 10.5vw;
        margin-top: 5rem;
    }
    .banner-heading{
        font-size: 5rem;
    }
    .banner-subheading{
        margin-top: 1rem;
        font-size: 3rem;
    }
}