#message {
    display: none;
}

#contact-wrapper {
    position: relative;
    width: 90%;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.20);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 1rem;
}

.Connect {
    color: #00AAE4;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.25rem;
    font-weight: 500;
    padding-top: 1rem;
}

.subHeading {
    color: #FFF;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}

.consent-font {
    color: #FFF;
    text-align: justify;
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 400;
}

.consent-font a {
    color: #FFF;
}

.borrowerLeadGeneration .col-md-6 {
    width: 100%;
}

.borrowerLeadGeneration .form-control {
    background-color: transparent;
    color: #FFF;
}

.borrowerLeadGeneration .form-control::placeholder {
    color: #FFF;
}

.borrowerLeadGeneration .text-blue {
    color: #FFF;
}
.btn-submit {
    background:transparent;
    border-radius: 8.01px;
    border: 0.823px solid #FFF;
    border-color: #FFF;
    font-weight: 500;
    box-shadow: 0 43px 90px 0 rgba(255, 81, 36, 0.10);
    font-size: 1.25rem;
    color:  #00AAE4;
}

.btn-submit:hover,
.btn-submit:active  {
    background-color: #00AAE4;
    color: #fff;
}
@media screen and (max-width: 1199.9px) and (min-width: 992px) {
    .subHeading{
        font-size: 12px;
    }
}
@media screen and (max-width: 991.9px)  {
    #contact-wrapper {
        width: 100%;
    }
    .formContainer{
        padding-left: 0;
        padding-right: 0;
    }
    .subHeading{
        font-size: 11px;
    }
}
@media screen and (max-width: 991.9px) and (min-width: 768px) {
    .subHeading{
        font-size: 1rem;
    }
    .Connect {
        font-size: 2rem;
    }
}